.sidebar {
  width: 20%;
  position: absolute;
  height: 100%;
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
}


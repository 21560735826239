.ClientsHead {
    background-color: #fff;
    margin-bottom: -110px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
}

.ClientsHead h2 {
    color: #140530;
    margin: auto;
    margin-top: 5px;
}

.ClientsMain {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px;
}

.Clientimg {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    max-width: 20vw;
    max-height: 20vh;
}

.Clientimg img{
    width: 100%;
    height: 100%;
    aspect-ratio: 3/2;
    object-fit: contain;
}

@media screen and (max-width: 600px) {

.ClientsHead h2 {
    font-size: 1.5rem;
}

}


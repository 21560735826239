.heading_setors {
  background: #fff;
  padding: 10px;
  position: relative;
  border-radius: 8px;
  box-shadow: rgb(3 0 71 / 9%) 0px 1px 3px;
  margin: 10px;
  align-items: center;
}

.d_flex_setors {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.f_flex_setors {
  display: flex;
}

.heading_setors .heading-left h2 {
  font-size: 22px;
  margin: 5px;
  margin-left: 0;
  color: #140530;
}
.heading_setors .heading-right {
  text-align: right;
  color: blue;
  margin: 5px;
  margin-right: 0;
}

.SectorsMain h4 {
  font-weight: 500;
}
.SectorsMain a {
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  background: #140530;
  padding: 5px 10px;
  border-radius: 50px;
}

.SectorsMain a:hover {
  background: #3dbfb0;
}

.SectorsMain .sector_box img{
  width: 100%;
  height: 30vh;
  border-radius: 8px;
}

.SectorsMain .sector_box h4{
  text-align: center;
  padding: 5px;
  font-weight: 500;
  color: #140530;
}

.SectorsMain {
  padding: 20px 0;
}

.container_sector {
  max-width: 95%;
  margin: auto;
}

.slick-slide > div {
    margin: 10px 10px;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-around;
}

@media screen and (min-width: 600px) {
    .slick-slide > div {
        margin: 10px 10px;
    }
} 

@media screen and (max-width: 600px) {
    .slick-slide > div {
        margin: 10px 25px;
    }
}

.slick-list {
    margin: 0 -10px;
}


.arrow.next, .arrow.prev {
    border-radius: 100%;
    /*border: 2px solid #fff;*/
    background-color: #140530;
    opacity: 0.5;
    /*box-shadow: 2px 2px 2px 2px rgb(0 0 0 / 12%);*/
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    position: absolute;
    top: 45%;
    z-index: 999;
}

.arrow.prev:hover, .arrow.next:hover {
    background: #3dbfb0;
    color: #fff;
    opacity: 1;
}

.arrow.prev {
    left: 0;
}

.arrow.next {
    right: 0;
}

.sector_product {
  background: #fff;
  padding: 20px;
  position: relative;
  border-radius: 8px;
  box-shadow: rgb(3 0 71 / 9%) 0px 1px 3px;
  margin: 10px;
}

.sector_product:hover {
  opacity: 1;
}

@media screen and (max-width: 600px) {

  .sector_grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
  }

  .SectorsMain .box h4{
    padding: 3px;
    font-size: .9rem;
    color: #140530;
  }

}

.sector_grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 30px;
}
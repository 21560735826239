footer {
    background: #201645;
    margin-top: 7rem;
    padding-top: 7rem;
    font-size: 0.9rem;
    color: white;
}

footer a{
    color: white;
}

.footer__container {
    display: grid;
    grid-template-columns: 26rem
    1fr 1fr 1fr;
    gap: 6rem;
}

.footer__container article {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.7rem;
}

.footer__container article h4 {
    margin-bottom: 0.6rem;
    font-size: 1rem;
    color: #3cbfad;
}

.footer__socials {
    margin-top: 1.5rem;
    display: flex;
    gap: 1rem;
    align-items: center;
}

.footer__socials a {
    background: #3cbfad;
    padding: 0.7rem;
    border: 1px solid transparent;
    border-radius: 0.6rem;
    transition: var(--transition);
}

.footer__socials a svg {
    color: white;
}

.footer__socials a:hover {
    border-color: #3cbfad;
    background: transparent;
}

.footer__copyright {
    color: white;
    text-align: center;
    padding: 1.5rem 0;
    border-top: 2px solid black;
    margin-top: 5rem;
}

@media screen and (max-width: 1024px) {
    .footer__container {
        grid-template-columns: 1fr 1fr;
        gap: 4rem;
    }
}

@media screen and (max-width: 600px) {
    footer {
        margin-top: 7rem;
    }
    .footer__container {
        grid-template-columns: 1fr;
        gap: 3rem;
    }

    .footer__container article {
        align-items: center;
    }

    .footer__container article p {
        text-align: center;
    }
}

.logo2 {
    width: 14rem;
    display: block;
}

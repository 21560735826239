.heading_categories {
  background: #fff;
  padding: 10px;
  position: relative;
  border-radius: 8px;
  box-shadow: rgb(3 0 71 / 9%) 0px 1px 3px;
  margin: 0px 10px 0px 10px;
  align-items: center;
}
.CategoriesMain h4 {
  font-weight: 500;
}
.CategoriesMain a {
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  background: #140530;
  padding: 5px 10px;
  border-radius: 50px;
}

.CategoriesMain a:hover {
  background: #3dbfb0;
}

.CategoriesMain .box img {
  width: 100%;
  height: 30vh;
  border-radius: 8px;
}

.CategoriesMain .box h4{
  text-align: center;
  padding: 5px;
  font-weight: 500;
  color: #140530;
}

.CategoriesMain {
  padding: 20px 0;
}


.d_flex_categories {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.f_flex_categories {
  display: flex;
}


.grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 30px;
}

.container_categories {
  max-width: 95%;
  margin: auto;
}


.heading_categories .heading-left h2 {
  font-size: 22px;
  margin: 5px;
  margin-left: 0;
  color: #140530;
}
.heading_categories .heading-right {
  text-align: right;
  color: blue;
  margin: 5px;
  margin-right: 0;
}


.product {
  background: #fff;
  padding: 10px;
  position: relative;
  border-radius: 8px;
  box-shadow: rgb(3 0 71 / 9%) 0px 1px 3px;
  margin: 10px;
}

.product:hover {
  opacity: 1;
}

@media screen and (max-width: 600px) {

  .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
  }

  .CategoriesMain .box h4{
    padding: 3px;
    font-size: .8rem;
    color: #140530;
  }

}



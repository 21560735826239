.homeSlide {
    background: #e2e2e2;
    padding-bottom: 5px;
    padding-top: 5px;
    display: flex;
    align-items: center;
}
.homeSlide h2 {
  font-size: 40px;
  /*line-height: 55px;*/
  margin: 10px 0 20px 0;
}
.homeSlide p {
  margin: 20px 0;
}
.homeSlide ul {
  position: absolute;
  bottom: 20px;
}
.homeSlide ul li {
  border: 2px solid #b8b7b7;
  border-radius: 50%;
  padding: 10px;
}
.homeSlide ul li button {
  background: transparent !important;
  color: transparent !important;
}
.homeSlide ul li button::before {
  font-size: 15px !important;
  color: #0f3460;
  position: absolute;
  top: 2px;
  left: 0;
  margin: auto;
}

.top_slider {
  margin-top: 25px;
  margin-bottom: 25px;
}

.btn-primary {
  background: #3dbfb0;
  padding: 12px 40px;
  font-weight: bold;
  color: #fff;
  border-radius: 50px;
  margin: 10px;
}

.btn-primary:hover {
  background: #140530;
  padding: 12px 40px;
  font-weight: bold;
  color: #3dbfb0;
  border-radius: 50px;
}

.btn-primary a {
    color: #fff;
}

.d_flex_slider {
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
}

.contentWidth {
  max-width: 100%;
}

.left {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 20px;
    color: #140530;
    margin-right: 100px;
}

.right img {
    width: 300px;
    height: 300px;
    margin: auto;
    margin-left: 100px;
    object-fit: contain;
}

@media screen and (max-width: 600px) {

    .left {
        font-size: .9rem;
        color: #140530;
        margin-right: 5px;
    }

    .right img {
        width: 150px;
        height: 150px;
        margin: auto;
        object-fit: contain;
        margin-left: 5px;
    }

    .btn-primary {
        background: #3dbfb0;
        padding: 5px 15px;
        font-weight: bold;
        color: #fff;
        border-radius: 50px;
    }

    .btn-primary:hover {
        background: #140530;
        padding: 5px 20px;
        font-weight: bold;
        color: #3dbfb0;
        border-radius: 50px;
    }

    .homeSlide p {
        margin: 10px 0;
    }
    .homeSlide h2 {
        font-size: 25px;
        /*line-height: 55px;*/
        margin: 0px 0 10px 0;
    }

    .top_slider {
      margin-top: 10px;
      margin-bottom: 10px;
    }

}
.head {
  background: linear-gradient(90deg, #3dbfb0, #140530);
  padding: 5px 0;
  color: #fff;
}
.head label {
  margin-right: 30px;
  font-size: 13px;
}

.rowhead {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: left;
  position: relative;
  margin: auto;
}

.d_flex1 {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    position: relative;
    align-items: center;
    margin: auto;
    max-width: 98%;
}

.rowhead a{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-left: 10px;
}

.head span {
    color: #fff;
    font-size: 1rem;
}

.head img {
    width: 1.5rem;
    height: 1.5rem;
}

@media screen and (max-width: 600px) {
    .head span {
        color: #fff;
        font-size: .7rem;
    }

    .head img {
        width: 1.1rem;
        height: 1.1rem;
    }

    .rowhead a{
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        padding-left: 10px;
        vertical-align: middle;
        align-items: center;
        align-content: center;
    }

    .d_flex1 {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    position: relative;
    align-items: center;
    margin: auto;
    max-width: 98%;
    }
}
.SpecialAdsMain {
  position: relative;
  margin: auto;
  max-width: 95%;
  margin-top: -15px;
}
.SpecialAdsMain img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
}
.SpecialAdsMain .nametop {
  position: relative;
  width: 100%;
}
.SpecialAdsMain .nametop span {
  background: #140530;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  padding: 5px 10px;
  border-radius: 30px 100px/120px;
  margin: 5px;
  position: absolute;
  top: 10px;
}
.SpecialAdsMain .nametop .tleft {
  left: 0;
}
.SpecialAdsMain .nametop .tright {
  background-color: #3dbfb0;
  color: #fff;
  position: absolute;
  right: 0;
}


.SpecialAdsproduct {
  background: #fff;
  padding: 8px;
  position: relative;
  border-radius: 8px;
  box-shadow: rgb(3 0 71 / 9%) 0px 1px 3px;
  margin: 10px;
}

.d_flex_SpecialAds {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}


@media screen and (max-width: 600px) {

  .SpecialAdsMain {
    position: relative;
    margin: auto;
    max-width: 100%;
  }
  .SpecialAdsproduct {
      margin: 5px;

  }
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*, *::before, *::after {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  list-style: none;
  text-decoration: none;
  box-sizing: border-box;
}

:root {
  --container-width-lg: 80%;
  --container-width-md: 90%;

  --transition: all 500ms ease;
}

.container {
  width: var(--container-width-lg);
  max-width: 1920px;
  margin-inline: auto;
}

h1, h2, h3, h4, h5 {
  line-height: 1.2;
  /*color: ;*/
}

h1 {
  font-size: 3rem;
}

h2 {
  font-size: 2rem;
}

/*a {
  color: white;
}*/

img {
  display: block;
  object-fit: cover;
  width: 100%;
}


/* Media queries*/
@media screen and (max-width: 1024px) {
  .container {
    width: var(--container-width-md);
  }

  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.6rem;
  }
}


@media screen and (max-width: 600px) {
  h1 {
    font-size: 2.2rem;
    line-height: 1.3;
  }
}

h1 {
    align-items: center;
    text-align: center;
}